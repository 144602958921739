export function getInternalLinkUrl(item: any): string {
  if (item) {
    switch (item.__typename) {
      case "Page":
        return `/${item.slug}` || null;
      case "BlogArticle":
        return item.slug ? `/blog/${item.slug}` : null;
      case "Documentation":
        return `/${item.type}/docs`;
      default:
        if (item.slug) {
          return `/${item.slug}`;
        }
        if (!item.__typename) {
          console.error(` item`, item);
          throw new Error(`Unknown internal link type`);
        }
        throw new Error(`Unknown internal link type: ${item.__typename}`);
    }
  }
  return null;
}
