"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISButton {
  $buttonStyle?: string | undefined | null;
  $buttonShape?: string | undefined | null;
  $disableStyle?: boolean;
  $activated?: boolean;
  $isDisabled?: boolean;
}

const CSSDisableStyle = css`
  background: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none;

  &:hover {
    background: none;
  }

  &:active,
  &:focus {
    background: none;
  }
`;

const CSSSecondaryButton = css`
  background: var(--interactional-tertiary-03) !important;
  border: 1px solid var(--ui-03) !important;
  color: var(--text-01) !important;

  &:hover {
    background: var(--hover-interactional-tertiary-03) !important;
  }

  &:active,
  &:focus {
    background: var(--active-interactional-tertiary-03) !important;
  }

  &:disabled {
    background: var(--disabled-interactional-01) !important;
  }
`;

const CSSTertiaryButton = css<{ $activated?: boolean }>`
  background: none !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;

  &:hover,
  &:active,
  &:focus {
    border-color: #ffffff !important;
    background: none !important;
  }

  ${({ $activated }) =>
    $activated &&
    `
      border-color: #ffffff !important;
      background: none !important;
    `}

  &:disabled {
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
    color: rgba(255, 255, 255, 0.25) !important;
    background: none !important;
  }
`;

const CSSBronzeSmallButton = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary"
      ? "8px 16px"
      : "7px 15px"} !important;
  font-family: var(--font-inter) !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
`;

const CSSDiamondSmallButton = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary"
      ? "12px 20px"
      : "11px 19px"} !important;
  font-family: var(--font-inter) !important;
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.02em !important;
`;

const CSSBronzePromoButton = css<{ $activated?: boolean }>`
  background: rgba(255, 255, 255, 0.15) !important;
  display: flex;

  gap: 6px !important;
  ${CSSBronzeSmallButton};
  padding: 8px 16px 8px 16px !important;

  &:hover {
    background: rgba(255, 255, 255, 0.25) !important;
  }

  &:active,
  &:focus {
    background: rgba(255, 255, 255, 0.4) !important;
  }

  ${({ $activated }) =>
    $activated &&
    `
    background: rgba(255, 255, 255, 0.4) !important;
  `}

  &:disabled {
    background: rgba(255, 255, 255, 0.15) !important;
    color: var(--disabled-text) !important;
  }
`;

const CSSBronzeRoundButton = css`
  background: rgba(0, 0, 0, 0.65) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: var(--big-elevation) !important;
  border-radius: 50% !important;
  padding: 15px !important;
  color: var(--icon-02) !important;
  line-height: 0 !important;

  &:hover {
    background: rgba(0, 0, 0, 0.65) !important;
  }

  &:active,
  &:focus {
    background: rgba(0, 0, 0, 0.65) !important;
  }

  &:disabled {
    background: var(--disabled-interactional-01) !important;
    color: var(--disabled-text) !important;
  }
`;

const CSSDiamondRoundButton = css`
  ${CSSBronzeRoundButton};
  padding: 19px !important;
`;

const Bronze = css<ISButton>`
  background: var(--interactional-primary-01) !important;
  border-radius: 100px !important;
  color: var(--text-02) !important;
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary"
      ? "16px 24px"
      : "15px 23px"} !important;
  width: fit-content !important;
  border: none !important;
  cursor: pointer !important;
  transition-property: border-color, background-color !important;
  transition-duration: 300ms !important;
  font-family: var(--font-inter) !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  letter-spacing: -0.02em !important;

  &:hover {
    background: var(--hover-interactional-primary-01);
  }

  &:active,
  &:focus {
    background: var(--active-interactional-primary-01);
  }

  ${({ $buttonStyle }) => $buttonStyle === "secondary" && CSSSecondaryButton};
  ${({ $buttonStyle }) => $buttonStyle === "tertiary" && CSSTertiaryButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "small" && CSSBronzeSmallButton};
  ${({ $buttonStyle }) => $buttonStyle === "promo" && CSSBronzePromoButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "round" && CSSBronzeRoundButton};
  ${({ $disableStyle }) => ($disableStyle ? CSSDisableStyle : "")};
`;

const Silver = css<ISButton>``;

const Gold = css<ISButton>``;

const Platinum = css<ISButton>``;

const Diamond = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary"
      ? " 20px 32px"
      : " 19px 31px"} !important;
  font-family: var(--font-inter) !important;
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.02em !important;
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "small" && CSSDiamondSmallButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "round" && CSSDiamondRoundButton};
`;

export const STypeformButton = styled.div<ISButton>`
  button {
    height: unset !important;
    ${Bronze}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
  }
`;
