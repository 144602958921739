import { css } from "styled-components";

// HEADING

export const CSSHeading01 = css`
  font-family: var(--font-inter);
  font-size: 60px;
  line-height: 72px;
  font-weight: 800;
  letter-spacing: -0.04em;
`;

export const CSSHeading01XLarge = css`
  font-family: var(--font-inter);
  font-size: 72px;
  line-height: 84px;
  font-weight: 800;
  letter-spacing: -0.04em;
`;

export const CSSHeading01Small = css`
  font-family: var(--font-inter);
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
  letter-spacing: -0.02em;
`;

export const CSSHeading02 = css`
  font-family: var(--font-inter);
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  letter-spacing: -0.03em;
`;

export const CSSHeading02XLarge = css`
  font-family: var(--font-inter);
  font-size: 52px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: -0.03em;
`;

export const CSSHeading02Small = css`
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
`;

export const CSSHeading03 = css`
  font-family: var(--font-inter);
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -0.03em;
`;

export const CSSHeading03XLarge = css`
  font-family: var(--font-inter);
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.03em;
`;

export const CSSHeading03Small = css`
  font-family: var(--font-inter);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
`;

// Titles

export const CSSTitle01 = css`
  font-family: var(--font-inter);
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.01em;
`;

export const CSSTitle01Xlarge = css`
  font-family: var(--font-inter);
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -0.01em;
`;

export const CSSTitle02 = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const CSSTitle02Xlarge = css`
  font-family: var(--font-inter);
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0;
`;

// Subtitles
export const CSSSubtitle01 = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSBody01XLarge = css`
  font-family: var(--font-inter);
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const CSSSubtitle01MedXLarge = css`
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

export const CSSSubtitle01XLarge = css`
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

export const CSSSubtitle01Med = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSSubtitle01Semi = css`
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const CSSSubtitle01SemiXlarge = css`
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

// Body

export const CSSBody01MedXLarge = css`
  font-family: var(--font-inter);
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSBody01Semi = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

// Body

export const CSSBody01Med = css`
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

export const CSSBody02Semi = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSBody02SemiXLarge = css`
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

export const CssBody02XLarge = css`
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
`;

export const CssBody02Med = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSBody02MedXLarge = css`
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

export const CSSBody02 = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSBody03Semi = css`
  font-family: var(--font-inter);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSBody03SemiXLarge = css`
  font-family: var(--font-inter);
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSBody03Med = css`
  font-family: var(--font-inter);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSBody03MedXLarge = css`
  font-family: var(--font-inter);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSBody03 = css`
  font-family: var(--font-inter);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSBody03XLarge = css`
  font-family: var(--font-inter);
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0;
`;

// Caption

export const CSSCaption01Semi = css`
  font-family: var(--font-inter);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSCaption01SemiXLarge = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSCaption01Med = css`
  font-family: var(--font-inter);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSCaption01MedXLarge = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSCaption01 = css`
  font-family: var(--font-inter);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSCaption01XLarge = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSCaption02Semi = css`
  font-family: var(--font-inter);
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSCaption02SemiXLarge = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const CSSCaption02Med = css`
  font-family: var(--font-inter);
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSCaption02MedXLarge = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const CSSCaption02 = css`
  font-family: var(--font-inter);
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0;
`;

export const CSSCaption02XLarge = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

// Highlight

export const CSSHighlight = css`
  font-family: var(--font-inter);
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.06em;
`;

export const CSSHighlightXLarge = css`
  font-family: var(--font-inter);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.06em;
`;

export const CSSHighlightMicro = css`
  font-family: var(--font-inter);
  font-size: 8px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.08em;
`;

// Buttons

export const CSSPrimaryButtons = css`
  font-family: var(--font-inter);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

export const CSSPrimaryButtonsXLarge = css`
  font-family: var(--font-inter);
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.02em;
`;

export const CSSmallButtons = css`
  font-family: var(--font-inter);
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const CSSSmallButtonsXLarge = css`
  font-family: var(--font-inter);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
`;
