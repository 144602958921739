"use client";
import Link from "next/link";
import { FC } from "react";
import { getInternalLinkUrl } from "../../utils/helpers/getInternalLinkUrl";
import { SButton } from "./styles/SButton";
import { SButtonPromo } from "./styles/SButtonPromo";
import {
  ANALYTICS_CLASSNAME_BASE,
  ANALYTICS_CLASSNAME_GLOBAL_ACTIVE,
  toSnakeCase,
} from "../../constants/classnames";
import { IGenButtonProps } from "./Button";
import { TypeformButton } from "../typeform-button/TypeformButton";
import { STypeformButton } from "../typeform-button/styles/STypeformButton";

export const ButtonInner: FC<IGenButtonProps> = ({
  buttonStyle,
  promoText,
  children,
  url,
  shape,
  internalLink,
  disableStyle,
  disabled,
  activated,
  linkTarget,
  eventName,
  ...props
}) => {
  const isHasLink = internalLink && !!getInternalLinkUrl(internalLink[0]);
  const ButtonContainer = ({ children }) => {
    if (url) {
      return (
        <a
          href={url}
          target={
            linkTarget
              ? linkTarget
              : url.includes("https://")
              ? "_blank"
              : undefined
          }
        >
          {children}
        </a>
      );
    }
    if (isHasLink && internalLink?.[0]?.__typename) {
      if (
        [
          "Page",
          "Blog",
          "BlogArticle",
          "Documentation",
          "DocumentationArticle",
        ].includes(internalLink[0].__typename)
      ) {
        return (
          <Link href={`${getInternalLinkUrl(internalLink[0])}`}>
            {children}
          </Link>
        );
      } else {
        return (
          <a href={`#${getInternalLinkUrl(internalLink[0])}`}>{children}</a>
        );
      }
    }
    return <>{children}</>;
  };

  const className = eventName
    ? `${ANALYTICS_CLASSNAME_GLOBAL_ACTIVE} ` +
      ANALYTICS_CLASSNAME_BASE +
      toSnakeCase(`${eventName}`)
    : undefined;

  if (url && `${url}`.includes("typeform_button::")) {
    return (
      <STypeformButton
        {...props}
        $isDisabled={disabled}
        $buttonStyle={buttonStyle}
        $buttonShape={shape}
        title={undefined}
        $disableStyle={disableStyle}
        id={undefined}
        __typename={undefined}
        $activated={activated}
        className={className}
      >
        <TypeformButton
          typeformId={`${url}`.replace("typeform_button::", "")}
        />
      </STypeformButton>
    );
  }

  return (
    <ButtonContainer>
      <SButton
        {...props}
        $isDisabled={disabled}
        $buttonStyle={buttonStyle}
        $buttonShape={shape}
        title={undefined}
        $disableStyle={disableStyle}
        id={undefined}
        __typename={undefined}
        $activated={activated}
        className={className}
      >
        {children}
        {!!promoText && (
          <SButtonPromo>
            <p>{promoText}</p>
          </SButtonPromo>
        )}
      </SButton>
    </ButtonContainer>
  );
};
