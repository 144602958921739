import Script from "next/script";

export interface ICalendlyModal {
  typeformId: string;
}

export const TypeformButton: React.FC<ICalendlyModal> = ({
  typeformId,
}): JSX.Element => {
  return (
    <>
      <>
        <div
          className="typeform-button-wrapper"
          data-tf-live={typeformId}
        ></div>
        <Script src="//embed.typeform.com/next/embed.js"></Script>
      </>
    </>
  );
};
