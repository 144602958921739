"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  background: #ffffff;
  border-radius: 12px;
  font-weight: 800;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 4px 6px;

  p {
    color: var(--text-01);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  font-weight: 800;
  font-size: 9px;
  line-height: 14px;
`;

export const SButtonPromo = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
