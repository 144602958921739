export const COL_AMOUNT = {
  BRONZE: 4,
  SILVER: 12,
  GOLD: 12,
  PLATINUM: 12,
  DIAMOND: 12,
};

export const SCREEN_TYPES = {
  BRONZE: "BRONZE",
  SILVER: "SILVER",
  GOLD: "GOLD",
  PLATINUM: "PLATINUM",
  DIAMOND: "DIAMOND",
};

// @TODO
export const GUTTER = {
  BRONZE: "24px",
  SILVER: "24px",
  GOLD: "24px",
  PLATINUM: "24px",
  DIAMOND: "24px",
};

export const PADDING = {
  BRONZE: "24px",
  SILVER: "24px",
  GOLD: "52px",
  PLATINUM: "52px",
  DIAMOND: "76px",
};

export const MAX_WIDTH = 1920;
