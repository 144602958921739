"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSCaption01Med,
  CSSCaption01MedXLarge,
  CSSmallButtons,
  CSSPrimaryButtons,
  CSSPrimaryButtonsXLarge,
  CSSSmallButtonsXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";
import { SButtonPromo } from "./SButtonPromo";
// import { Maybe } from "graphql/jsutils/Maybe";

interface ISButton {
  $buttonStyle?: string | undefined | null;
  $buttonShape?: string | undefined | null;
  $disableStyle?: boolean;
  $activated?: boolean;
  $isDisabled?: boolean;
}

const CSSDisableStyle = css`
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  box-shadow: none;

  &:hover {
    background: none;
  }

  &:active,
  &:focus {
    background: none;
  }
`;

const CSSSecondaryButton = css`
  background: var(--interactional-tertiary-03);
  border: 1px solid var(--ui-03);
  color: var(--text-01);

  &:hover {
    background: var(--hover-interactional-tertiary-03);
  }

  &:active,
  &:focus {
    background: var(--active-interactional-tertiary-03);
  }

  &:disabled {
    background: var(--disabled-interactional-01);
  }
`;

const CSSTertiaryButton = css<{ $activated?: boolean }>`
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.15);

  &:hover,
  &:active,
  &:focus {
    border-color: #ffffff;
    background: none;
  }

  ${({ $activated }) =>
    $activated &&
    `
      border-color: #ffffff;
      background: none;
    `}

  &:disabled {
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.25);
    background: none;
  }
`;

const CSSBronzeSmallButton = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary" ? "8px 16px" : "7px 15px"};
  ${CSSmallButtons};
`;

const CSSDiamondSmallButton = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary" ? "12px 20px" : "11px 19px"};

  ${CSSSmallButtonsXLarge};
`;

const CSSBronzePromoButton = css<{ $activated?: boolean }>`
  background: rgba(255, 255, 255, 0.15);
  display: flex;

  gap: 6px;
  ${CSSBronzeSmallButton};
  padding: 8px 16px 8px 16px;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  &:active,
  &:focus {
    background: rgba(255, 255, 255, 0.4);
  }

  ${({ $activated }) =>
    $activated &&
    `
    background: rgba(255, 255, 255, 0.4);
  `}

  &:disabled {
    background: rgba(255, 255, 255, 0.15);
    color: var(--disabled-text);

    ${SButtonPromo} {
      p {
        color: var(--disabled-text);
      }
    }
  }
`;

const CSSDiamondPromoButton = css`
  gap: 8px;
  ${CSSDiamondSmallButton};
  padding: 12px 20px;
`;

const CSSBronzeRoundButton = css`
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var(--big-elevation);
  border-radius: 50%;
  padding: 15px;
  color: var(--icon-02);
  line-height: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.65);
  }

  &:active,
  &:focus {
    background: rgba(0, 0, 0, 0.65);
  }

  &:disabled {
    background: var(--disabled-interactional-01);
    color: var(--disabled-text);
  }
`;

const CSSDiamondRoundButton = css`
  ${CSSBronzeRoundButton};
  padding: 19px;
`;

const CSSBronzeFooterLink = css`
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  color: var(--text-01);
  box-shadow: none;
  ${CSSCaption01Med};

  &:hover {
    background: none;
  }

  &:active,
  &:focus {
    background: none;
  }
`;

const CSSDiamondFooterLink = css`
  ${CSSCaption01MedXLarge};
`;

const CSSDisabled = css`
  background: var(--disabled-interactional-01);
  color: var(--disabled-text);
  border: 0;
  pointer-events: none;
`;

const Bronze = css<ISButton>`
  background: var(--interactional-primary-01);
  border-radius: 100px;
  color: var(--text-02);
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary" ? "16px 24px" : "15px 23px"};
  width: fit-content;
  border: none;
  cursor: pointer;
  transition-property: border-color, background-color;
  transition-duration: 300ms;
  ${CSSPrimaryButtons};

  &:hover {
    background: var(--hover-interactional-primary-01);
  }

  &:active,
  &:focus {
    background: var(--active-interactional-primary-01);
  }

  ${({ $isDisabled }) => $isDisabled && CSSDisabled}

  ${({ $buttonStyle }) => $buttonStyle === "secondary" && CSSSecondaryButton};
  ${({ $buttonStyle }) => $buttonStyle === "tertiary" && CSSTertiaryButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "small" && CSSBronzeSmallButton};
  ${({ $buttonStyle }) => $buttonStyle === "promo" && CSSBronzePromoButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "round" && CSSBronzeRoundButton};
  ${({ $buttonStyle }) => $buttonStyle === "footer" && CSSBronzeFooterLink};
  ${({ $disableStyle }) => ($disableStyle ? CSSDisableStyle : "")};
`;

const Silver = css<ISButton>``;

const Gold = css<ISButton>``;

const Platinum = css<ISButton>``;

const Diamond = css<ISButton>`
  padding: ${({ $buttonStyle }) =>
    !$buttonStyle || $buttonStyle === "primary" ? " 20px 32px" : " 19px 31px"};
  ${CSSPrimaryButtonsXLarge};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "small" && CSSDiamondSmallButton};
  ${({ $buttonStyle }) => $buttonStyle === "promo" && CSSDiamondPromoButton};
  ${({ $buttonShape }) =>
    `${$buttonShape}`.toLowerCase() === "round" && CSSDiamondRoundButton};
  ${({ $buttonStyle }) => $buttonStyle === "footer" && CSSBronzeFooterLink};
  ${({ $buttonStyle }) => $buttonStyle === "footer" && CSSDiamondFooterLink};
  ${({ $disableStyle }) => ($disableStyle ? CSSDisableStyle : "")};
`;

export const SButton = styled.button<ISButton>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
