export const ANALYTICS_CLASSNAME_BASE = "ct__";
export const ANALYTICS_CLASSNAME_GLOBAL_ACTIVE = "ct_g";

export function toSnakeCase(str) {
  return str
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
}

export const ANALYTICS_CLASSNAMES = {
  CLICK: ANALYTICS_CLASSNAME_BASE + "c",
  VISIBLE: ANALYTICS_CLASSNAME_BASE + "v",
  COMB: ANALYTICS_CLASSNAME_BASE + "c" + " " + ANALYTICS_CLASSNAME_BASE + "v",
};
